@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Museo";
  src: url("../public/fonts/Museo300-Regular.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Museo";
  src: url("../public/fonts/Museo700-Regular.otf");
  font-weight: 700;
  font-style: bold;
}

body {
  margin: 0;
  font-family: "Museo", sans-serif;
}

input,
button, textarea,select,option {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #F8FAF9 inset !important;
  caret-color: #000;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}

h1 {
  font-size: 2.25rem;
  line-height: 1;
}

h1, p, span, label {
  font-weight: 700;
}

span, label {
  color: #0C3B2E
}

.first-mobile-align {
  @apply flex w-full min-h-screen justify-center items-center;
}

.second-mobile-align {
  @apply w-full h-full min-h-screen max-w-[430px] p-8;
}
